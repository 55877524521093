import styled from "@emotion/styled";
import React, { useEffect, useMemo, useState } from "react";

import { asset_status_enum_enum } from "~src/__generated__/graphql/types";
import { Flexbox } from "~src/designSystem/layout/Flexbox";
import {
  BPSPercentageRenderer,
  InvestorAssetStatusRenderer,
} from "~src/designSystem/tables/Table/renderer/custom";
import { renderers } from "~src/designSystem/tables/Table/renderer/native";
import { List } from "~src/shared/lists/components/List";
import { useListData } from "~src/shared/lists/hooks/useListData";
import { IListColumnConfig, IListConfig, IListFilter } from "~src/shared/lists/types";
import { IListModel } from "~src/shared/lists/types/models";
import { IFilterOp } from "~src/shared/lists/types/operators";
import { IRenderType } from "~src/shared/lists/types/types";

interface IProps {
  status?: asset_status_enum_enum;
}

const getListConfig = (
  status: asset_status_enum_enum | null,
): IListConfig<IListModel.lists_investor_asset_receipts> => {
  const filters: IListFilter<IListModel.lists_investor_asset_receipts>[] = [];

  if (status !== null) {
    filters.push({
      column: "asset_status",
      operator: IFilterOp.eq,
      value: status,
    });
  }

  const columns: IListColumnConfig<IListModel.lists_investor_asset_receipts>[] = [
    {
      name: "public_id",
      title: "Contract ID",
      width: "minmax(196px, 1fr)",
    },
    {
      name: "pseudonym",
      title: "Vendor",
      width: "minmax(196px, 1fr)",
    },
    {
      name: "asset_status",
      title: "Status",
      renderer: InvestorAssetStatusRenderer,
      width: "minmax(112px, 1fr)",
    },
  ];

  if (status === asset_status_enum_enum.swapped) {
    columns.push({
      name: "swapped_with",
      title: "Swapped With",
      width: "minmax(196px, 1fr)",
    });
    columns.push({
      name: "swapped_amount",
      title: "Swapped Amount",
      renderer: renderers[IRenderType.cents],
      width: "minmax(112px, 1fr)",
    });
  } else {
    columns.push({
      name: "percentage_paid",
      title: "% Paid",
      renderer: renderers[IRenderType.percent],
      width: "minmax(112px, 1fr)",
    });
    columns.push({
      name: "outstanding_balance",
      title: "Outstanding",
      renderer: renderers[IRenderType.cents],
      width: "minmax(112px, 1fr)",
    });
  }

  columns.push({
    name: "asset_rating",
    title: "Rating",
    width: "minmax(112px, 1fr)",
  });
  columns.push({
    name: "irr_bps",
    title: "IRR",
    renderer: BPSPercentageRenderer,
    width: "minmax(112px, 1fr)",
  });

  const iListConfig: IListConfig<IListModel.lists_investor_asset_receipts> = {
    model: IListModel.lists_investor_asset_receipts,
    columns,
    filters,
  };
  return iListConfig;
};

export const InvestorAssetReceiptsList: React.FC<IProps> = ({ status }: IProps) => {
  const iListConfig = useMemo(() => getListConfig(status ?? null), [status]);
  const [config, setConfig] =
    useState<IListConfig<IListModel.lists_investor_asset_receipts>>(iListConfig);

  const listData = useListData(config);
  useEffect(() => {
    setConfig(iListConfig);
  }, [iListConfig, status]);
  return (
    <ListWrapper>
      <List<IListModel.lists_investor_asset_receipts>
        data={listData}
        setConfig={setConfig}
        title="Contracts"
        leftPadding={28}
      />
    </ListWrapper>
  );
};

const ListWrapper = styled(Flexbox)`
  height: 100%;
  flex-grow: 2;
  position: relative;
`;
