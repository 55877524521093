import styled from "@emotion/styled";
import React, { useEffect } from "react";

import { asset_status_enum_enum } from "~src/__generated__/graphql/types";
import { PageHeader } from "~src/designSystem/deprecated/PageHeader";
import { Flexbox } from "~src/designSystem/layout/Flexbox";
import { Spacer } from "~src/designSystem/layout/Spacer";
import { Touchable } from "~src/investor/shared/Touchable";
import { ISegmentTrackPage, useAnalytics } from "~src/shared/thirdParties/segment";

import { InvestorAssetReceiptsList } from "./InvestorAssetReceiptsList";

export enum IAssetStatusLabel {
  Active = "Active",
  All = "All",
  Delinquent = "Delinquent",
  Investigating = "Investigating",
  PaidOff = "Paid off",
  Swapped = "Swapped",
}

export const InvestorContracts: React.FC = () => {
  const { trackPage } = useAnalytics();
  useEffect(() => {
    trackPage(ISegmentTrackPage.InvestorContractsPageImpression);
  }, [trackPage]);

  const [tabIndex, setTabIndex] = React.useState<number>(0);
  const tabs = [
    {
      label: IAssetStatusLabel.All,
      component: <InvestorAssetReceiptsList />,
    },
    {
      label: IAssetStatusLabel.Active,
      component: <InvestorAssetReceiptsList status={asset_status_enum_enum.servicing} />,
    },
    {
      label: IAssetStatusLabel.PaidOff,
      component: <InvestorAssetReceiptsList status={asset_status_enum_enum.completed} />,
    },
    {
      label: IAssetStatusLabel.Swapped,
      component: <InvestorAssetReceiptsList status={asset_status_enum_enum.swapped} />,
    },
  ];

  return (
    <>
      <Flexbox height="100%" flex-shrink>
        <PageHeader
          title="Contracts"
          description="Below is a list of all contracts you've purchased to date and the existing performance
          status."
        />
        <Spacer css={{ $$y: "48px" }} />
        <Flexbox row>
          {tabs.map(({ label }, i) => (
            <Touchable
              key={label}
              onClick={() => setTabIndex(i)}
              selected={label === tabs[tabIndex]?.label}
            >
              {label}
            </Touchable>
          ))}
        </Flexbox>
        <ListWrapper>{tabs[tabIndex]?.component}</ListWrapper>
      </Flexbox>
    </>
  );
};

const ListWrapper = styled.div`
  margin: 24px -48px 0;
  width: calc(100% + 48px * 2);
  height: 600px;
  flex-grow: 1;
`;
