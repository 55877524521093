/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { useMemo } from "react";

import {
  asset_status_enum_enum,
  investor_payment_batch_status_enum_enum,
  limit_buy_order_status_enum_enum,
} from "~src/__generated__/graphql/types";
import { IStatusTagColor, StatusTag } from "~src/designSystem/atoms/StatusTag";
import { CompanyWithLogo } from "~src/designSystem/molecules/CompanyWithLogo";
import { makeRenderer } from "~src/designSystem/tables/Table/renderer/native";
import { ITableRendererProps } from "~src/designSystem/tables/Table/types";
import { IAssetStatusLabel } from "~src/investor/contracts/page";
import { formatBidPrice, formatCents } from "~src/shared/helpers/format";
import { IListRowData } from "~src/shared/lists/types";
import { IListModel } from "~src/shared/lists/types/models";
import { useProposalStore } from "~src/vendor/inbox/contexts/ProposalsContext";
import {
  calculatePayoutFromTradeableContract,
  computeBalanceFromBase,
  computePayoutFromBase,
} from "~src/vendor/inbox/utils/adjustableTerms";

export const CustomerRenderer: React.FC<ITableRendererProps<string>> = (props) => {
  const { row, value } = props;
  const v = value(row);

  return (
    <CompanyWithLogo
      name={v !== "" ? v : "--"}
      // TODO(md): Do we need to cast here?
      email={(row.data.email ?? row.data.customer_email ?? "") as string}
      css={css`
        min-width: fit-content;
      `}
    />
  );
};

// For use by admins.
export const VendorRenderer: React.FC<ITableRendererProps<string>> = (props) => {
  const { row, value } = props;
  const v = value(row);

  return (
    <CompanyWithLogo
      name={v !== "" ? v : "--"}
      // TODO(md): Do we need to cast here?
      email={`@${row.data.vendor_client_id ?? ""}`}
      css={css`
        min-width: fit-content;
      `}
    />
  );
};

export const PayoutRenderer = makeRenderer<number>((_, { row }) => {
  const termLength = useProposalStore((s) => s.selectedTermLength);

  const payout = useMemo(
    () =>
      calculatePayoutFromTradeableContract(
        row.data as IListRowData<IListModel.lists_tradeable_contracts>,
        termLength,
      ),
    [row.data, termLength],
  );

  const currency = (row.data.currency ?? "usd") as string;
  const formatted = formatCents(payout, currency);

  return <>{formatted}</>;
});

export const SelectedMonthsRenderer = makeRenderer<number>((maxTermLengthForContract) => {
  const selectedTermLengthForOrder = useProposalStore((s) => s.selectedTermLength);
  // Ensure that if there's a contract that ends before the order's selected term length, we show the accurate value.
  // e.g. If a user has 12mo contracts, but one has a 7 months left, ensure we show 7mo instead of 12mo for that specific contract
  const termLength = Math.min(selectedTermLengthForOrder, maxTermLengthForContract);
  return <>{termLength} months</>;
});

export const BidPriceRenderer = makeRenderer<number>((_, { row }) => {
  const maxTermLengthForContract = row.data.max_tradeable_term_length as number;
  const baseBalance = row.data.base_balance as number;
  const baseRateBps = row.data.base_rate_bps as number;
  const currency = (row.data.currency ?? "usd") as string;

  const selectedTermLengthForOrder = useProposalStore((s) => s.selectedTermLength);
  const termLength = Math.min(selectedTermLengthForOrder, maxTermLengthForContract);

  const bidPriceComputed = useMemo(() => {
    const payout = computePayoutFromBase(termLength, baseBalance, baseRateBps);
    const balance = computeBalanceFromBase(termLength, baseBalance);
    return payout / balance;
  }, [termLength, baseBalance, baseRateBps]);

  const bidPriceFormatted = useMemo(() => {
    return formatBidPrice(bidPriceComputed, currency);
  }, [bidPriceComputed, currency]);

  return <>{bidPriceFormatted}</>;
});

export const MonthsRenderer = makeRenderer<number>((terms) => {
  if (terms === 1) {
    return <>{terms} month</>;
  }
  return <>{terms} months</>;
});

export const MonthsLeftRenderer = makeRenderer<number>((terms) => {
  let monthsLeft = terms;
  if (terms < 0) {
    monthsLeft = 0;
  }
  return <>{monthsLeft} months</>;
});

export const BPSPercentageRenderer = makeRenderer<number>((bps) => {
  return <>{bps / 100}%</>;
});

export const InvertedCentsRenderer = makeRenderer<number>((v, { row }) => {
  // TODO(usmanm): better way to pass currency column
  const currency = (row.data.currency ?? "usd") as string;
  const formatted = formatCents(v, currency, { invertSign: true });

  return <>{formatted}</>;
});

export const InvestorOrderStatusRenderer = makeRenderer<limit_buy_order_status_enum_enum>(
  (status) => {
    // TODO: Took a guess at the colors. Audit and fix later as needed
    const tagColor = ((): IStatusTagColor => {
      switch (status) {
        case limit_buy_order_status_enum_enum.completed: {
          return "green";
        }
        case limit_buy_order_status_enum_enum.active: {
          return "blue";
        }
        case limit_buy_order_status_enum_enum.canceled: {
          return "red";
        }
        default: {
          return "neutral";
        }
      }
    })();

    return <StatusTag label={status} color={tagColor} />;
  },
);

export const InvestorAssetStatusRenderer = makeRenderer<asset_status_enum_enum>((status) => {
  const tagColor = ((): IStatusTagColor => {
    switch (status) {
      case asset_status_enum_enum.completed:
        return "green";
      case asset_status_enum_enum.churned:
        return "yellow";
      case asset_status_enum_enum.servicing:
        return "blue";
      case asset_status_enum_enum.investigating:
        return "orange";
      default:
        return "neutral";
    }
  })();

  const label = ((): IAssetStatusLabel => {
    switch (status) {
      case asset_status_enum_enum.completed:
        return IAssetStatusLabel.PaidOff;
      case asset_status_enum_enum.churned:
        return IAssetStatusLabel.Delinquent;
      case asset_status_enum_enum.servicing:
        return IAssetStatusLabel.Active;
      case asset_status_enum_enum.investigating:
        return IAssetStatusLabel.Investigating;
      case asset_status_enum_enum.swapped:
        return IAssetStatusLabel.Swapped;
      default:
        throw new Error(`unknown investor asset status enum: ${status}`);
    }
  })();

  return <StatusTag label={label} color={tagColor} />;
});

export const InvestorDisbursalStatusRenderer =
  makeRenderer<investor_payment_batch_status_enum_enum>((status) => {
    // TODO: Took a guess at the colors. Audit and fix later as needed
    const tagColor = ((): IStatusTagColor => {
      switch (status) {
        case investor_payment_batch_status_enum_enum.completed: {
          return "green";
        }
        case investor_payment_batch_status_enum_enum.failed: {
          return "red";
        }
        case investor_payment_batch_status_enum_enum.cancelled: {
          return "red";
        }
        case investor_payment_batch_status_enum_enum.initiated: {
          return "blue";
        }
        case investor_payment_batch_status_enum_enum.pending: {
          return "yellow";
        }
        default: {
          return "neutral";
        }
      }
    })();

    return <StatusTag label={status} color={tagColor} />;
  });

export const UserStatusRenderer = makeRenderer<string>((status) => {
  const tagColor = ((): IStatusTagColor => {
    switch (status) {
      case "Disabled":
        return "red";
      case "Active":
        return "green";
      // This is the case of "Invited at ...".
      default:
        return "neutral";
    }
  })();

  return <StatusTag label={status} color={tagColor} />;
});
